import React, { Component } from "react"
import Circle from '@uiw/react-color-circle';

class ColorSwatch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hex: this.props.colors[0]
        };
    }

    render() {
        const { onChange } = this.props;
        return (
            <Circle
                colors={this.props.colors}
                color={this.state.hex}
                onChange={(color) => {
                    this.setState({hex: color.hex})
                    onChange(color.hex)
                }
                }
            />
        )
    }
}

export default ColorSwatch