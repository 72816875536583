import React, { Component } from "react";
import CanvasDraw from "react-canvas-draw";
import ColorSwatch from "./ColorSwatch"
import RangeSlider from 'react-bootstrap-range-slider';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';

const brushSizeStyle = {
  width: "100%"
}

class GameCanvas extends Component {
    
  
    state = {
      // backgroundColor:  "#ffffff",
      color: "#ffc600",
      width: 1000,
      height: 1000,
      brushRadius: 10,
      lazyRadius: 5
    };

    componentDidMount() {
      this.setState({
        color: this.colorSwatch.hex,
        // backgroundColor: this.bgColorSwatch.hex
      })

      const savedData = localStorage.getItem(
        "savedDrawing",
      );
      if(savedData) {
        this.saveableCanvas.loadSaveData(savedData)
      }
    }
    render() {
      return (
        <div>
          <div >
            <button
              onClick={() => {
                localStorage.setItem(
                  "savedDrawing",
                  this.saveableCanvas.getSaveData()
                );
              }}
            >
              Save
            </button>
            <button
              onClick={() => {
                this.saveableCanvas.eraseAll();
              }}
            >
              Erase
            </button>
            <button
              onClick={() => {
                this.saveableCanvas.undo();
              }}
            >
              Undo
            </button>
            <button
              onClick={() => {
                console.log(this.saveableCanvas.getDataURL());
                alert("DataURL written to console")
              }}
            >
              GetDataURL
            </button>
            <div>
              <label>Brush Size:</label>
              <RangeSlider
                min={1}
                max={100}
                value={this.state.brushRadius}
                style={brushSizeStyle}
                tooltipLabel={currentValue => `${currentValue}%`}
                size="lg"
                onChange={e =>
                  this.setState({ brushRadius: parseInt(e.target.value, 10) })
                }
              />
            </div>
            <div>
              <label>Brush Color:</label>
              <ColorSwatch
                ref={colorSwatch => (this.colorSwatch = colorSwatch)} 
                colors={
                  ['#000000', '#FFFFFF', '#f44336', '#ff5722', '#ffeb3b', 
                  '#4caf50', '#2196f3', '#9c27b0', '#795548', '#607d8b']
                }
                onChange={(color) =>
                  this.setState({ color: color })
                }
              />
            </div>
          </div>
          <div className="game-canvas">
          <CanvasDraw
            // hideGrid
            ref={canvasDraw => (this.saveableCanvas = canvasDraw)}
            brushColor={this.state.color}
            brushRadius={this.state.brushRadius}
            lazyRadius={this.state.lazyRadius}
            canvasWidth={this.state.width}
            canvasHeight={this.state.height}
            backgroundColor={this.state.backgroundColor}
            enablePanAndZoom={true}
          />
          </div>
        </div>
      );
    }
  }
  
export default GameCanvas