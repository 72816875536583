import * as React from "react"
import Layout from "../../components/Layout"
import {
  Container,
  Row,
  Col
} from "react-bootstrap"
import GameCanvas from "../../components/GameCanvas"


const GamePage = () => (

  
   <Layout>
     <Container className="roadmap-container game mobile-mt-m10 mt-m15 mobile-pt-7 mobile-pb-10" style={{position:"relative", zIndex:"2"}}>
        <Row>
          <Col xs={12} lg={6} className="text-container">
          
            <h1 className="title">
              Art Submission
            </h1>
            <p>
            Submit your art and earn $PPR token!
            </p>
            
          </Col>

        </Row>
     <Row>
         <Col xs={12}>
         <GameCanvas />
       
         </Col>
     </Row>
     
      
       
        </Container>
       

   

</Layout>
)

export default GamePage
